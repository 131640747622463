import React, { useState } from "react"
import { InView } from "react-intersection-observer"

const IntersectionObserver = props => {
  const [inView, setInView] = useState(false)

  return !inView ? <InView onChange={setInView}>{inView && props.children}</InView>: props.children
}

export default IntersectionObserver
